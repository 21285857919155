// salamanca template

$brand-primary: #b3000f; // rojo
$brand-secondary: #e6d200; // amarillo
$brand-tertiary: #e86100; // anaranjado

$primary: $brand-primary;
$secondary: lighten($brand-secondary, 33%);

$headings-color: $primary;

$link-color: $brand-tertiary;
$link-color-dark: lighten($brand-tertiary, 10%);

$navbar-light-color: lighten($brand-secondary, 43%);
$navbar-light-hover-color:          $secondary;
$navbar-light-active-color:         $secondary;
$navbar-light-disabled-color:       desaturate($navbar-light-color, 100%);
$navbar-light-toggler-border:       $secondary;

@import "base";
@import "clock";
@import "social-icons";

#topnav {
	background-color: $brand-tertiary;
}

#jumbotron {
	@include gradient-radial($body-bg, lighten($brand-secondary, 33%));

	&[data-background] {
		color: $white;
		background: $gray-800 none;

		p, h1, h2, h3, h4 {
			text-shadow: 1px 1px 4px $black;
		}

		a:not([class]) {
			color: $secondary;
		}

		h1, h2, h3, h4 {
			color: $white;
		}

		.input-group {
			border-radius: $border-radius-lg;
			box-shadow: 1px 1px 4px $black;
		}
	}
}

footer {
	background-color: lighten($brand-secondary, 43%);
	color: $brand-tertiary;
}

@include color-mode(dark) {
	#jumbotron {
		@include gradient-radial($body-bg-dark, $body-secondary-bg-dark);
	}

	footer {
		background-color: $body-secondary-bg-dark;
		color: $gray-500;

		a, .nav-link {
			color: $gray-200;

			&:hover,
			&:focus {
				color: $white;
			}

		}
	}
}
