.social-icons [class^="icon-"], .social-icons [class*=" icon-"] {
	font-size: 2rem;
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	text-align: center;
	color: $headings-color;
	background-color: transparent;
	border-radius: 50%;
    transition: all 0.5s ease;

	&:hover, &:active {
		color: $white;
		background-color: $headings-color;
	}
}

@include color-mode(dark) {
	.social-icons [class^="icon-"], .social-icons [class*=" icon-"] {
		color: $headings-color-dark;

		&:hover, &:active {
			color: $body-bg-dark;
			background-color: $headings-color-dark;
		}
	}
}
